const filters = [
    'Design',
    'Development',
    'Mobile',
    'Machine Learning',
    'Hardware & IoT',
    '3D'
];

const projects = {
    highMuseum: {
        imageSelector: `HighMuseum`,
        themeColor: `#CD9723`,
        title: `Visitor App for High Museum of Art`,
        description: `Designing a mobile app to improve visitor engagement with artworks at the High Museum of Art, Atlanta, GA`,
        categories: ['Mobile', 'Design'],
        filter: ['Mobile','Design'],
        link: `/projects/high-museum`,
        date: `September - December 2021`,
        stack: [`Figma`, `Miro`],
        team: [`Abhinav Thukral`, `Avery Ao`, `Catherine Yang`, `Holly Sun`],
        info: ``,
        roles: [`UX Designer`, `UX Researcher`],
    },
    aesop: {
        imageSelector: `Aesop`,
        themeColor: `#F37E4B`,
        title: `Aesop: Digital Storytelling`,
        description: `Block-based programming tool that allows storytellers to quickly author immersive digital experiences`,
        categories: ['UI/UX Design', 'Development', 'UX Research'],
        filter: ['Design', 'Development', 'Machine Learning', 'Hardware & IoT'],
        link: `/projects/aesop`,
        date: `June - August 2019`,
        stack: ['Javascript', 'Firebase', 'Raspberry Pi', 'Python', 'HTML, CSS'],
        info: null,
        // roles: [
        //     `Designed and developed the block-based programming interface capable of compiling and running dynamic logic from scratch`,
        //     `Integrated speech, expression, gesture, and music recognition`,
        //     `Helped conduct user studies and evaluate results`
        // ],
        roles: [`UX Designer`, `UX Researcher`, `Full-Stack Developer`]
    },
    botsAtWork: {
        imageSelector: `BotsAtWork`,
        themeColor: `#5182FE`,
        title: `Bots at Work: Code less, design more.`,
        description: `Talk about a web design and see it come to life instantly with this conversational agent`,
        categories: ['Interaction Design', 'UX Design', 'Development'],
        filter: ['Design', 'Development'],
        link: `/projects/bots-at-work`,
        date: `March 2019`,
        stack: [`Javascript`, 'HTML, CSS'],
        info: ``,
    },
    basicsOf: {
        imageSelector: `BasicsOf`,
        themeColor: `#58BA8B`,
        title: `Basics Of: Machine Learning`,
        description: `3D interactive space to simply explain how machine learning works`,
        categories: ['3D Design', 'UX Design', 'Development'],
        filter: ['3D','Design','Development','Machine Learning'],
        link: `/projects/basics-of`,
        date: `December 2021`,
        stack: [`Three.js`, `React`],
        info: ``,
    },
    workaholic: {
        imageSelector: `Workaholic`,
        themeColor: `#39BBBB`,
        title: `Workaholic v2`,
        description: `A platform that encourages healthy behaviour as one sits for long hours in front of their computers / screens`,
        categories: ['UX Design', 'Development'],
        filter: ['Design', 'Development'],
        link: `/projects/workaholic`,
        date: `January 2021`,
        stack: [`Figma`, `React`, `Django`],
        info: ``,
    },
    postureAnalysis: {
        imageSelector: `PostureAnalysis`,
        themeColor: `#F59A67`,
        title: `Posture Analysis`,
        description: `Non-intrusive wearable sensor to monitor posture and provide feedback to encourage good posture throughout the day`,
        categories: ['Hardware & IoT', 'Machine Learning'],
        filter: ['Hardware & IoT', 'Machine Learning'],
        link: `/projects/posture-analysis`,
        date: `May 2019`,
        stack: [`Arduino`,`Firebase`,`HTML, CSS`],
        info: ``,
    },
    signLanguageTranslation: {
        imageSelector: `SignLanguageTranslation`,
        themeColor: `#EE6A8A`,
        title: `Sign Language Translation`,
        description: `Translating real-time american sign language to speech with an ordinary camera`,
        categories: ['Machine Learning'],
        filter: ['Machine Learning'],
        link: `/projects/sign-language-translation`,
        date: `December 2018`,
        stack: [`Python`,`OpenCV`,`Keras, Tensorflow`],
        info: ``,
    },
    mastercard: {
        imageSelector: `Mastercard`,
        themeColor: `#EA332B`,
        title: `Mastercard`,
        description: `Predicting customer purchase behaviour based on their purchase history`,
        categories: ['Machine Learning'],
        filter: ['Machine Learning'],
        link: `/projects/mastercard`,
        date: `June - July 2018`,
        stack: [`Python`,`Keras, Tensorflow`, `Angular 6`],
        info: ``,
    },
    poeticAI: {
        imageSelector: `PoeticAI`,
        themeColor: `#A8DCDC`,
        title: `Poetic.ai`,
        description: `Finding out if an AI can learn to do creative tasks like writing poetry or stories`,
        categories: ['Machine Learning'],
        filter: ['Machine Learning'],
        link: `/projects/poetic-ai`,
        date: `July 2018`,
        stack: [`Python`,`Keras, Tensorflow`],
        info: ``,
    },
};

export { filters, projects };