import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"

import * as style from './read-next.module.scss'

import { Aesop, BotsAtWork, BasicsOf, Workaholic, PostureAnalysis, SignLanguageTranslation, Mastercard, FrogEPFL, PoeticAI } from '../covers';

const ProjectThumbnail = ({imageSelector}) => {
    switch(imageSelector){
        case 'Aesop':
            return <Aesop />
        case 'BotsAtWork':
            return <BotsAtWork />
        case 'BasicsOf':
            return <BasicsOf />
        case 'Workaholic':
            return <Workaholic />
        case 'PostureAnalysis':
            return <PostureAnalysis />
        case 'SignLanguageTranslation':
            return <SignLanguageTranslation />
        case 'Mastercard':
            return <Mastercard />
        case 'FrogEPFL':
            return <FrogEPFL />
        case 'PoeticAI':
            return <PoeticAI />
        default:
            return null;
    }
}

const ReadNext = ({projectObj}) => {
    return (
    <>
      <div className={`content--md-center`}><hr /></div>
      <section className={`fix-bottom`}>
          <div className={`content--md-center`}>
          <h6 className={`center`}>Read Next</h6>
              <div className={`data-hover add-margin-bottom`}>
                    <Link to={projectObj.link}>
                        <div className={style.thumbnail}>
                            <div className={style.imageWrapper}>
                                <ProjectThumbnail imageSelector={projectObj.imageSelector} />
                            </div>
                        </div>
                        <h2 className={`center`}>{projectObj.title}</h2>
                    </Link>
              </div>
              <div className={`center-content`}>
                <Link to='/projects'><button className={`data-hover`}>Back to Projects</button></Link>
                </div>
          </div>
      </section>
    </>
    )
}

ReadNext.propTypes = {
  projectObj: PropTypes.object,
}

ReadNext.defaultProps = {
    projectObj: {
        imageSelector: ``,
        themeColor: `#ccc`,
        title: ``,
        description: ``,
        categories: [],
        filter: [],
        link: ``,
        date: ``,
        stack: [],
        info: ``,
    },
}

export default ReadNext
