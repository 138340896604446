import React, { useEffect } from "react"
import smoothscroll from 'smoothscroll-polyfill';
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/free-solid-svg-icons"

const isServer = typeof window === 'undefined';

if(!isServer){
    smoothscroll.polyfill();
}

const Index = ({chapters}) => {

    useEffect(() => {
        const index = document.getElementById('chapters');
        const section = document.getElementById('overview');
        const boundsTop = section.getBoundingClientRect().top;
        const defaultScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const offset = boundsTop + defaultScrollTop

        window.addEventListener('scroll', () => {
            let scroll = window.scrollY;
            if(scroll > offset) {
                index.classList.add('fix');
            } else {
                index.classList.remove('fix');
            }
        });
    }, []);

    const smoothScroll = (sectionName) => {
        // console.log(sectionName);
        const elem = document.getElementById(sectionName);
        let bounds = elem?.getBoundingClientRect().top;
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if(elem) { 
            window.scroll({
                top: bounds + scrollTop - 5*16,
                behavior: "smooth",
            });
        }
    }

    return (
      <div className={`index`} id={`chapters`}>
          <div className={`title`}>Content</div>
          {chapters.map((chapter)=>(
              <div className={`chapter`} onClick={() => { smoothScroll(chapter.scrollTo) }}>{chapter.name}</div>
          ))}
      </div>
    )
}

Index.propTypes = {
  chapters: PropTypes.array,
}

Index.defaultProps = {
  chapters: [],
}

export default Index
